body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f4f7f9;
  margin: 0;
  padding: 20px;
}

.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  color: #4a90e2;
  text-align: center;
  margin-bottom: 30px;
}

.filters {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 30px;
}

.filters label {
  display: block;
  margin-bottom: 5px;
  color: #4a90e2;
}

.filters input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.filters button {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filters button:hover {
  background-color: #3a7bc8;
}

.club-list {
  list-style-type: none;
  padding: 0;
}

.club {
  background-color: #ffffff;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.club:hover {
  transform: translateY(-5px);
}

.club h3 {
  color: #f5a623;
  margin-top: 0;
}

.club p {
  margin: 5px 0;
}

@media (max-width: 600px) {
  body {
    padding: 10px;
  }

  .App {
    padding: 10px;
  }
}